import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { setCookie } from '@/helpers/cookie'

// global
import Main from '@/components/Main/MainPage.vue'
import Admin from '@/components/Admin/AdminBlock.vue'
import { useStore } from '@/pinia/global'
// Clients
const Clients = () => import(/* webpackChunkName: "clients" */ '@/components/Admin/Clients/list/ClientsList.vue')
const Client = () => import(/* webpackChunkName: "client" */ '@/components/Admin/Clients/item/ClientItem.vue')
// Documents
const Documents = () => import(/* webpackChunkName: "documents" */ '@/components/Admin/Documents/list/DocumentsPage.vue')
const Document = () => import(/* webpackChunkName: "document" */ '@/components/Admin/Documents/item/DocumentItem.vue')
const Settings = () => import(/* webpackChunkName: "settings" */ '@/components/Admin/Settings/Settings.vue')
const ClientInfo =
  () => import(/* webpackChunkName: "client" */ '@/components/Admin/Clients/item/ClientInfo/ClientInfoBlock.vue')

const middleware = async (to:any, from:any, next:any) => {
  const role = await useStore().getRole
  const params = from.params.platform ? from.params : to.params
  switch (to.name) {
    case 'clients':
    case 'client':
      if (role.isCustomer) next()
      else if (!role.isCustomer && role.isDocument) next({ name: 'documents', params })
      else next()
      break
    case 'documents':
    case 'document-id':
      if (role.isDocument) next()
      else if (!role.isDocument && role.isCustomer) next({ name: 'clients', params })
      else next()
      break
    case 'settings':
      if (role.isAdmin) next()
      else if (role.isDocument) next({ name: 'documents', params })
      else if (role.isCustomer) next({ name: 'clients', params })
      else next()
      break
    default:
      next()
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Main
  },
  {
    path: '/authorize',
    name: 'login',
    beforeEnter: (to: any, from: any, next: any) => {
      auth(to)
      next()
    },
    component: Main
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to: any, from: any, next: any) => {
      useStore().logout()
      next()
    },
    component: Main
  },
  {
    path: '/admin/:platform',
    name: 'admin',
    component: Admin,
    // redirect: '/admin/:platform/clients',
    beforeEnter: async (to: any, from: any, next: any) => {
      await INIT_ADMIN({ to, next })
    },
    children: [
      {
        path: 'clients/',
        name: 'clients',
        component: Clients,
        meta: { title: 'Clients', filter: 'Clients', id: '0' },
        beforeEnter: middleware
      },
      {
        path: 'client/:id',
        name: 'client',
        component: Client,
        meta: { title: 'Client', filter: 'Client', id: '0' },
        beforeEnter: middleware
      },
      {
        path: 'documents/',
        name: 'documents',
        component: Documents,
        meta: { title: 'Documents', filter: 'Documents', id: '1' },
        beforeEnter: middleware
      },
      {
        path: 'documents/:id',
        name: 'document-id',
        component: Document,
        meta: { title: 'Document', filter: 'Document', id: '1' },
        beforeEnter: middleware
      },
      {
        path: 'clientinfo/:id',
        name: 'client_info',
        component: ClientInfo,
        meta: { title: 'Client Info', filter: 'ClientInfo', id: '4' },
        beforeEnter: middleware
      },
      {
        path: 'settings/',
        name: 'settings',
        component: Settings,
        meta: { title: 'Settings', filter: 'Settings', id: '3' },
        beforeEnter: middleware
      }
    ]
  }
  // {
  //   path: '/redirect',
  //   name: 'redirect',
  //   beforeEnter: (to:any, from:any, next:any) => {
  //     redirectParams(to.query, next)
  //   },
  //   component: Redirect
  // },
]

/**
 * @description Authorisation
 */
const auth = async (to:any) => {
  const code = to.query.code
  const token = await useStore().getToken(code)
  setCookie('token', token)
}

/**
 * @description Получение платформ согласно доступа аккаунта
 */
const INIT_ADMIN = async ({ to, next }:any) => {
  const routePlatform = to?.params?.platform || ''
  if (routePlatform) useStore().setPlatform(routePlatform)
  const responseInfo = await useStore().userInfo()

  INIT_DICT()
  if (responseInfo) {
    next()
  } else {
    window.location.replace('/')
  }
}

const INIT_DICT = () => {
  useStore().managersList('')
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
