<template>
  <div class="admin-bar">
    <div class="admin-bar_logo" @click="home">
      KYC
    </div>
    <div class="bar">
      <router-link :to="{name:'clients'}" v-if="roles.isCustomer">
        <div :class="route.meta.id === '0' ? 'bar-item active': 'bar-item'">Clients</div>
      </router-link>
<!--      <router-link :to="{name:'documents'}" v-if="roles.isDocument">-->
<!--        <div :class="$route.meta.id === '1' ? 'bar-item active': 'bar-item'">Documents</div>-->
<!--      </router-link>-->
<!--      <router-link :to="{name:'settings'}" v-if="roles.isAdmin">-->
<!--        <div :class="$route.meta.id === '3' ? 'bar-item active': 'bar-item'">Settings</div>-->
<!--      </router-link>-->
    </div>
    <span class="el-row--flex ae"/>
    <div class="bar-item_menu" v-if="false">
      <el-dropdown trigger="click">
        <div class="el-dropdown-link span4 wrap el-row--flex ac caps mx">
          <div class="caps">
            {{ platform }}
          </div>
          <img src="@/assets/icon/btn_arrow_down.svg" height="24" alt="" class="ml_5"/>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :key="item"
              :value="item"
              @click="setPlatform(item)"
              v-for="item in platforms">
              <div class="f-12 caps">{{ item }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="bar-item_menu">
      <el-dropdown trigger="click" @command="menuLink">
        <div class="bar-username">
          <img src="@/assets/icon/user.svg" alt="" height="40" width="40" class="mr-1"/>
          <div>
            <div class="span11">{{ username }} ({{ roles.type }})</div>
          </div>
          <img src="@/assets/icon/btn_arrow_down.svg" height="24" alt="" class="ml_5"/>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="menu.method" v-for="menu in menus" :key="menu.i">
              <div class="menu-item">
                <div class="menu-item_label">
                  <div :class="menu.icon" />
                  {{ menu.name }}
                </div>
                <div v-if="menu.body" class="menu-item_body">Build: {{ menu.body }}</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { deleteCookie } from '@/helpers/cookie'

import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/pinia/global'
import { EPlatform } from '@/api/project'
import { useCustomerStore } from '@/components/Admin/Clients/pinia'
// eslint-disable-next-line no-undef
const buildDateVal = dayjs(BUILD_DATE).format('HH:mm - DD/MM') || ''
const route:any = useRoute()
const router:any = useRouter()
// name: 'AdminBar',

const buildDate = ref(buildDateVal)

const username = computed(() => useStore().username)
const roles = computed(() => useStore().getRole)
const platform = computed(() => useStore().platform)
const platforms = computed(() => useStore().platforms)
const menus = computed(() => {
  return [
    {
      id: 10, name: 'Clear Cache', method: 'clearSw', icon: 'repeat', body: buildDate.value
    },
    {
      id: 9, name: 'Logout', method: 'logout', icon: 'logout'
    }
  ]
})

const clients = () => {
  return useCustomerStore().clientsList()
}
const setStatePlatform = (event:EPlatform) => {
  return useStore().setPlatform(event)
}
const home = () => {
  window.location.replace('/')
}

const setPlatform = (name:EPlatform) => {
  setStatePlatform(name)
  router.push({
    name: 'clients',
    params: { platform: name }
  })
  clients()
}

const clearSw = () => {
  if (navigator?.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
  deleteCookie('token')
  deleteCookie('patch')
  deleteCookie('platform')
  // localStorage.removeItem('vuex')
  router.push({ name: 'home' })
  window.location.reload()
}

const logout = () => {
  router.push({ name: 'logout' })
}

const menuLink = (name: string) => {
  switch (name) {
    case 'clearSw':
      clearSw()
      break
    case 'logout':
      logout()
      break
  }
}
</script>

<style lang="scss" scoped>
$height: 56px;

.admin-bar {
  width: 100%;
  height: $height;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
}

.admin-bar_logo {
  color: #FFFFFF;
  background-color: var(--active-color);
  padding: 0 115px;
  height: $height;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bar {
  display: flex;
  flex-wrap: nowrap;
  height: $height;
  user-select: none;
  align-items: center;
  width: 100%;
  margin-left: 32px;
}

.bar span {
  background-color: #ffffff;
  width: 100%;
  height: $height;
}

.bar-item {
  padding: 0 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  /*opacity: 0.66;*/
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #00000099;
  border-bottom: 4px solid transparent;
  height: $height - 2px;
  display: flex;
  align-items: center;
  margin-bottom: -2px;
  text-transform: uppercase;
  transition: .3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.active {
  border-color: var(--primary-color);
}

.bar-item_menu {
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--title-color);
  display: flex;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  height: $height;

  div {
    align-items: center;
  }
}

.bar-username {
  display: flex;
  align-items: center;
}
.menu-item {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.menu-item_label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  text-transform: uppercase;
}
.menu-item_body {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 10px;
}
@mixin icon {
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 9px;
  background-color: #000000;
}

.logout {
  @include icon;
  mask: url('@/assets/icon/logout.svg') no-repeat center;
}
.repeat {
  @include icon;
  mask: url('@/assets/icon/repeat.svg') no-repeat center;
}
</style>
