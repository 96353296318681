export default {
  en: {
    lang: 'English',
    ALL: 'ALL',
    ADMIN: 'Admin',
    CUSTOMER_ADMIN: 'Customer (Admin)',
    CUSTOMER_READ: 'Customer (Read)',
    DOCUMENT_READ: 'Document (Read)',
    DOCUMENT_ADMIN: 'Document (Admin)'
  }
}
